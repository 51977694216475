import * as React from 'react';
import tw from 'twin.macro';
import { ClassNames } from '@emotion/react';
import { graphql } from 'gatsby';
import { Trans } from '@lingui/macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import blogStyles from '../styles/blogStyle';
import Link from '../components/Link';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CallToAction from '../components/Home/CallToAction';
import { blogPage } from '../utils/url';
import './markdown-blog.scss';

const Heading = tw.h1`
text-3xl sm:text-5xl lg:text-5xl leading-none font-extrabold tracking-tight text-gray-900 mb-5 sm:mb-5 lg:leading-snug text-center
`;

const ContentWrapper = tw.div`min-w-0 w-full flex-auto lg:static lg:max-h-full lg:overflow-visible`;
const Wrapper = tw.div`lg:flex`;

const BlogTemplate = ({ pageContext, data }) => {
    const { seo } = pageContext;
    const { blog } = data;
    const { frontmatter, body } = blog;
    const {
        title,
        date,
        locale,
        cover,
    } = frontmatter;

    const image = getImage(cover);

    return (
        <Layout
            seo={seo}
            locale={locale}
        >
            <MDXProvider
                components={blogStyles}
            >
                <Header />
                <Container>
                    <Wrapper>
                        <ContentWrapper>
                            <Link tw="text-lg text-gray-500 block text-center hover:text-blue-700 hover:underline" to={blogPage()}>
                                <svg tw="inline-block fill-current" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z" /></svg>
                                <Trans>All articles</Trans>
                            </Link>
                            <Heading>{title}</Heading>
                            <div tw="text-center text-base sm:mb-5 text-indigo-900">{date}</div>
                            <article tw="prose bg-white m-auto max-w-4xl">
                                <ClassNames>
                                    {({ css }) => (
                                        <GatsbyImage
                                            image={image}
                                            alt={title}
                                            imgStyle={{ margin: '0 auto' }}
                                            layout="fullWidth"
                                            className={css`
                                            height: 400px;
                                            overflow: hidden;
                                            @media (max-width: 640px) {
                                                height: inherit;
                                            }
                                        `}
                                        />
                                    )}
                                </ClassNames>
                                <div tw="sm:py-12 sm:px-12 px-2">
                                    {/* <article
                                        className="blog-post-content"
                                        dangerouslySetInnerHTML={{ __html: html }}
                                    /> */}

                                    <MDXRenderer>
                                        {body}
                                    </MDXRenderer>

                                </div>
                            </article>
                        </ContentWrapper>
                    </Wrapper>
                </Container>
                <CallToAction />
                <Footer />
            </MDXProvider>
        </Layout>
    );
};

export default BlogTemplate;

export const pageQuery = graphql`
    query BlogPath($id: String!) {
        blog: mdx(id: { eq: $id } ) {
            body
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                locale
                cover {
                    childImageSharp {
                        gatsbyImageData(
                            width: 800
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            layout: FULL_WIDTH
                        )
                    }
                }
            }
        }
    }
`;
