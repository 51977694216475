import React from 'react';
import tw from 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';
import { appUrl } from '../config';

const H1 = tw.h2`
`;

const H2 = tw.h3`
`;

const H3 = tw.h4`
`;

const P = tw.p`
`;

const Info = ({ title, description }) => (
    <div tw="bg-green-100 border-t-4 border-green-500 rounded-b text-green-900 px-4 py-3 shadow-md" role="alert">
        <div tw="flex">
            <div tw="pt-2"><svg tw="fill-current h-6 w-6 text-green-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
            <div>
                {/* the style margin is used to override the margin style from prose - tailwindlabs/tailwindcss-typography */}
                <p tw="font-bold" style={{ margin: 0 }}>{title}</p>
                <p tw="text-sm mb-0" style={{ margin: 0 }}>{description}</p>
            </div>
        </div>
    </div>
);

const MoinAsset = () => (
    <a href={appUrl} tw="flex justify-center">
        <StaticImage src="../../static/img/fb-share.png" alt="MoinImage" width={600} />
    </a>
);

const componentMapping = {
    h1: H1,
    h2: H2,
    h3: H3,
    p: P,
    Info,
    MoinAsset,
};

export default componentMapping;
